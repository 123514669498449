.group:hover .group-hover\:fill-primary-1 {
    fill: #4667bc;
  }
  .bg-n-1 {
    background-color: #ffffff;
  }
  .bg-n-2 {
    background-color: #f7f8fa;
  }
  .bg-n-3 {
    background-color: #eff0f2;
  }
  .bg-n-5 {
    background-color: #141618;
  }
  .bg-n-7 {
    background-color: #232627;
  }
  .text-n-1 {
    color: #ffffff;
  }
  .text-n-3 {
    color: #9ea3ab;
  }
  .text-n-4 {
    color: #6c727c;
  }
  .text-n-4\/50 {
    color: rgba(108, 114, 124, 0.5);
  }
  .text-n-6 {
    color: #444444;
  }
  .dark\:text-n-1 {
    color: #ffffff;
  }
  .dark\:text-n-3 {
    color: #9ea3ab;
  }
  .dark\:text-n-4 {
    color: #6c727c;
  }
  .dark\:text-n-4\/50 {
    color: rgba(108, 114, 124, 0.5);
  }
  .fill-n-3 {
    fill: #9ea3ab;
  }
  .fill-primary-1 {
    fill: #4667bc;
  }
  .border-n-2 {
    border-color: #f7f8fa;
  }
  .btn-dark {
    background-color: #141618;
    color: #ffffff;
    transition: background-color 0.2s;
  }
  .btn-dark:hover {
    background-color: #232627;
  }
  .btn-small {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .btn-white {
    background-color: #ffffff;
    color: #444444;
    transition: background-color 0.2s;
  }
  .btn-white:hover {
    background-color: #eff0f2;
  }
  .caption1 {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  /* .shadow-[0_0_0_0.25rem_#FEFEFE] {
    box-shadow: 0 0 0 0.25rem #fefefe;
  }
  .dark\:shadow-[0_0_0_0.25rem_#232627] {
    box-shadow: 0 0 0 0.25rem #232627;
  }
  .shadow-[0_0.3125rem_0.75rem_-0.25rem_#C3CAD2] {
    box-shadow: 0 0.3125rem 0.75rem -0.25rem #c3cad2;
  }
  .animate-\[loaderDots_0.6s_0s_infinite_alternate\] {
    animation: loaderDots 0.6s 0s infinite alternate;
  }
  .animate-\[loaderDots_0.6s_0.3s_infinite_alternate\] {
    animation: loaderDots 0.6s 0.3s infinite alternate;
  }
  .animate-\[loaderDots_0.6s_0.6s_infinite_alternate\] {
    animation: loaderDots 0.6s 0.6s infinite alternate;
  } */
  .ui-open\:z-10 {
    z-index: 10;
  }
  .ui-open\:bg-n-4\/50 {
    background-color: rgba(108, 114, 124, 0.5);
  }
  .dark\:ui-open\:bg-n-1\/20 {
    background-color: rgba(255, 255, 255, 0.2);
  }


.scrollbar-none{

  scrollbar-width: none;

}
.markdown-content {
 line-height: 1.6;
  font-size: 16px;

}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #2c3e50;
}

.markdown-content h1 { font-size: 2em; border-bottom: 1px solid #eaecef; padding-bottom: 0.3em; }
.markdown-content h2 { font-size: 1.5em; border-bottom: 1px solid #eaecef; padding-bottom: 0.3em; }
.markdown-content h3 { font-size: 1.25em; }
.markdown-content h4 { font-size: 1em; }
.markdown-content h5 { font-size: 0.875em; }
.markdown-content h6 { font-size: 0.85em; color: #6a737d; }

.markdown-content p {
  margin-bottom: 16px;
}

.markdown-content a {
  color: #0366d6;
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

.markdown-content code {
  background-color: rgba(27,31,35,0.05);
  border-radius: 3px;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 85%;
  margin: 0;
  padding: 0.2em 0.4em;
}

.markdown-content pre {
  background-color: #f6f8fa;
  border-radius: 3px;
  font-size: 85%;
  line-height: 1.45;
  overflow: auto;
  padding: 16px;
}

.markdown-content pre code {
  background-color: transparent;
  border: 0;
  display: inline;
  line-height: inherit;
  margin: 0;
  max-width: auto;
  overflow: visible;
  padding: 0;
  word-wrap: normal;
}

.markdown-content blockquote {
  border-left: 0.25em solid #dfe2e5;
  color: #6a737d;
  padding: 0 1em;
  margin: 0 0 16px 0;
}

.markdown-content ul,
.markdown-content ol {
  padding-left: 2em;
  margin-bottom: 16px;
}

.markdown-content li {
  margin-bottom: 0.25em;
}

.markdown-content li::before {
  content: "•";
  color: #0366d6;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.markdown-content table {
  border-collapse: collapse;
  margin-bottom: 16px;
  width: 100%;
}

.markdown-content table th,
.markdown-content table td {
  border: 1px solid #dfe2e5;
  padding: 6px 13px;
}

.markdown-content table tr:nth-child(2n) {
  background-color: #f6f8fa;
}

.markdown-content img {
  max-width: 100%;
  box-sizing: content-box;
  background-color: #fff;
}

.markdown-content hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}